import React, { Component } from "react";
import {
  MDBDataTable,
  MDBTableHead,
  MDBTableBody,
  MDBTable,
  MDBBtn,
} from "mdbreact";
import {
  AvInput,
  Card,
  CardBody,
  Col,
  Form,
  Row,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Button,
  Modal,
  Container,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  closeChat,
  sendMessageAdmin,
  getFoodAddOnById,
  getChatListAdmin,
  getChatListAdminById,
} from "../../Endpoint/Admin/index";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { ToastContainer, toast } from "react-toastify";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "./chat.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { first } from "lodash";

class ChatList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isLoaded: false,
      updatemodal: false,
      modal: false,
      message: "",
      amount: "",
      foodAddOnId: "",

      success: {},
      breadcrumbItems: [
        { title: "", link: "#" },
        { title: "", link: "#" },
      ],
      customchk: true,
      toggleSwitch: true,
      tableData: {
        columns: [
          {
            label: " ChatFromName",
            field: "chatFromName",
            sort: "asc",
            width: 60,
          },
          {
            label: " ChatStatus ",
            field: "chatStatus",
            sort: "asc",
            width: 60,
          },

          {
            label: " ChatLastMessage ",
            field: "chatLastMessage",
            sort: "asc",
            width: 60,
          },

          {
            label: "SendMessage",
            field: "sendMessageAdmin",
            sort: "asc",
            width: 100,
          },

          {
            label: "Closechat",
            field: "Closechat",
            sort: "asc",
            width: 100,
          },
        ],
        rows: [],
      },
      selectedChatHistory: [],
    };
    this.reader = new FileReader();
    this.handleChange = this.handleChange.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.editChatList = this.editChatList.bind(this);
    this.updatetoggle = this.updatetoggle.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  toggle(chatHistory) {
    // Load chat history messages when modal is opened
    if (chatHistory) {
      console.log("leeeeeeeeeeeeeeeeeeeeeeeeeee->", chatHistory);
      this.loadChatHistory(chatHistory);
    }
    this.setState({
      modal: !this.state.modal,
      message: "",
      chatMasterId: "",
    });
  }
  updatetoggle() {
    this.setState({
      updatemodal: !this.state.updatemodal,
    });
  }

  handleCloseModal() {
    this.setState({
      updatemodal: false,
      modal: false,
      selectedChatHistory: [], // Clear chat history when closing modal
      message: "", // Clear message input
      chatMasterId: "",
    });
  }

  handleChange = (e) => {
    this.setState(
      {
        [e.target.id]: e.target.value,
      },
      () => {}
    );
  };

  handleSubmit(e) {
    e.preventDefault();
    const userObject = {
      message: this.state.message,
      chatMasterId: this.state.chatMasterId,
    };

    sendMessageAdmin(userObject)
      .then((response) => {
        if (response.status !== 200) {
          toast.error(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          this.setState({
            handleResponse: {
              isSuccess: response.status === 200,
              message: "success",
              message: "",
              chatMasterId: "",
            },
          });
          this.getALlluser();
          this.handleCloseModal();
          toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        toast.error("error");
      });
  }
  getALlluser() {
    getChatListAdmin().then((result) => {
      console.log("Ala reeeeeeeeeeeee->", result);
      this.setState({
        isLoaded: true,
        items: result.chatData,
        tableData: {
          ...this.state.tableData,
          rows: result.chatData,
          list: result.chatData.map((x) => {
            x.chatStatus = x.chatStatus === "Open" ? "active" : "Close";
            // x.status =  'Open' ? 'active' : 'deactive'
            x.sendMessageAdmin = (
              <button
                className="btn btn-primary"
                onClick={() => this.editChatList(x.chatId.toString(), x)}
              >
                Send Message
              </button>
            );
            x.Closechat = (
              <button
                className="btn btn-danger"
                onClick={() =>
                  this.Closechat(x.chatId.toString(), x.chatStatus)
                }
              >
                {x.chatStatus === "active" ? "active" : "Close"}
              </button>
            );

            //  x.remove = <button className="btn btn-danger" onClick={() => this.removeFood(x._id.toString(),x.isActive)}>{x.isActive === true ? 'Deactive' : 'Active'}</button>

            //  console.log(x);
            return x;
          }),
        },
      });
    });
  }

  // Function to load chat history messages
  loadChatHistory(chatHistory) {
    if (chatHistory.chatId) {
      getChatListAdminById(chatHistory.chatId.toString())
        .then((result) => {
          console.log(result.chatHistory);
          this.setState({
            selectedChatHistory: result.chatHistory, // Set chat history messages in state
            chatMasterId: result.data._id,
          });
        })
        .catch((error) => {
          toast.error("error");
        });
    }
  }

  editChatList(id) {
    console.log("who's ID:", id);
    getChatListAdminById(id)
      .then((result) => {
        console.log(result.chatHistory);
        this.setState({
          selectedChatHistory: result.chatHistory,
          chatMasterId: result.data._id,
        });
      })
      .catch((error) => {
        toast.error("error");
      });
    this.toggle(this.state.items.find((item) => item.chatId.toString() === id));
  }
  //close
  Closechat(id) {
    closeChat(id)
      .then((result) => {
        this.getALlluser();
        toast.success("Chat closed successfully");
      })
      .catch((error) => {
        toast.error("error");
      });
  }

  componentDidMount() {
    this.getALlluser();
  }

  render() {
    const { handleResponse, invalidImage, items, previewImage } = this.state;

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title=""
              breadcrumbItems={this.state.breadcrumbItems}
            />
            <Row>
              <Col xs={12}>
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className={this.props.className}
                >
                  <ModalHeader toggle={this.toggle}>
                    Message Details
                  </ModalHeader>
                  <ModalBody>
                    {/* Render chat history messages here */}
                    <div className="message-list">
                      {this.state.selectedChatHistory &&
                        this.state.selectedChatHistory.map((message, index) => {
                          const messageDate = new Date(message.time);
                          const formattedDate = new Intl.DateTimeFormat(
                            "en-US",
                            {
                              hour: "numeric",
                              minute: "numeric",
                              hour12: true,
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          ).format(messageDate);

                          return (
                            <div
                              key={index}
                              className={`message-container ${
                                message.fromUser.role === 1 ? "admin" : "user"
                              }`}
                            >
                              <div
                                className={`message ${
                                  message.fromUser.role === 1 ? "admin" : "user"
                                }`}
                              >
                                <p className="message-text">
                                  {message.messageText}
                                </p>
                                <p className="message-date">{formattedDate}</p>
                              </div>
                            </div>
                          );
                        })}
                    </div>

                    <AvForm onValidSubmit={this.handleSubmit}>
                      <Row form>
                        {/* <Col md={12}>
                          <FormGroup>
                            <Label for="chatFromName">Chat From Name:</Label>
                            <Input
                              type="text"
                              value={this.state.chatFromName}
                              onChange={this.handleChange}
                              id="chatFromName"
                              name="chatFromName"
                              required
                            />
                          </FormGroup>
                        </Col> */}
                        <Col md={12}>
                          <FormGroup>
                            <Label for="name">Type your message here:</Label>
                            <AvField
                              type="text"
                              value={this.state.message}
                              onChange={this.handleChange}
                              id="message"
                              name="message"
                              required
                            />
                          </FormGroup>
                        </Col>
                        {/* <Col md={6}>
                          <FormGroup>
                            <Label for="ID">chatMasterId</Label>
                            <AvField
                              type="text"
                              value={this.state.amount}
                              onChange={this.handleChange}
                              id="amount"
                              name="amount"
                              required
                            />
                          </FormGroup>
                        </Col> */}
                      </Row>

                      <div className="text-center">
                        <Button color="primary" size="sm">
                          Submit
                        </Button>{" "}
                      </div>
                    </AvForm>
                  </ModalBody>
                </Modal>

                {/* </CardBody>
</Card> */}
              </Col>
            </Row>

            <Breadcrumbs
              title="Data Tables"
              breadcrumbItems={this.state.breadcrumbItems}
            />

            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <h4 className="card-title">Chat List Datatable </h4>
                    <p className="card-title-desc"></p>

                    <MDBDataTable
                      responsive
                      bordered
                      data={this.state.tableData}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ToastContainer />
      </React.Fragment>
    );
  }
}

export default ChatList;
